<template>
  <b-row v-if="program">
    <b-col sm="4">
      <!-- Timeline -->
      <b-card class="d-none d-sm-block">
        <h4 class="mb-2">
          <b-row>
            <b-col
              cols="8"
              class="d-flex align-items-center"
            >
              <feather-icon
                icon="ListIcon"
                size="20"
              />
              <b-card-title class="mb-0 ml-2">
                <router-link
                  :to="{ name: 'champion-program-key-dates-list' }"
                  style="color: unset"
                >
                  Key dates
                </router-link>
              </b-card-title>
            </b-col>
          </b-row>
        </h4>
        <timeline
          :key-dates="keyDates"
          :timezone="programTimezone"
        />
      </b-card>
    </b-col>

    <!-- Program info -->
    <b-col sm="8">
      <b-row>
        <b-col>
          <program-alerts
          ></program-alerts>
        </b-col>
      </b-row>
    
    <!-- Program info -->
   
      <b-row>
        <b-col>
          <b-card>
            <b-row>
              <b-col
                sm="12"
                class="mb-1"
              >
                <b-card-title class="mb-1">
                  {{ program.name }}
                </b-card-title>
                <b-card-sub-title>
                  {{ program.client.name }}
                </b-card-sub-title>
              </b-col>

              <b-col
                cols="6"
                sm="4"
              >
                <p class="font-weight-bolder mb-0">
                  Language
                </p>
                <p>{{ programLanguage }}</p>
              </b-col>
              <b-col
                cols="6"
                sm="4"
              >
                <p class="font-weight-bolder mb-0">
                  Default champion
                </p>
                <p>{{ programDefaultChampion }}</p>
              </b-col>
              <b-col
                cols="6"
                sm="4"
              >
                <p class="font-weight-bolder mb-0">
                  Match duration
                </p>
                <p>{{ programMatchDuration }}</p>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>

      <b-row>
        <!-- Live matches -->
        <b-col
          v-if="displayCurrentMatchWidget"
          sm="6"
        >
          <router-link
            :to="{ name: 'champion-matches-live-matches' }"
            style="color: unset"
          >
            <b-card tag="article">
              <b-row>
                <b-col
                  class="d-flex flex-column justify-content-between"
                >
                  <div>
                    <b-card-title
                      class="text-truncate"
                      style="margin-bottom: 3px"
                    >
                      Live Matches
                    </b-card-title>
                    <p class="font-small-3">
                      Active
                    </p>
                  </div>
                  <b-card-text class="mb-0 h1 font-weight-bold">
                    {{ activeMatchesCount }} of {{ matchLimit }}
                  </b-card-text>
                </b-col>
                <b-col
                  class="p-0"
                >
                  <div
                    id="chart"
                  >
                    <apexchart
                      type="radialBar"
                      :options="currentMatchPercentageChartOptions"
                      :series="currentMatchPercentage"
                    />
                  </div>
                </b-col>
              </b-row>
            </b-card>
          </router-link>
        </b-col>

        <b-col sm="6">
          <router-link
            :to="{ name: 'champion-participants' }"
            style="color: unset"
          >
            <b-card tag="article">
              <b-row>
                <b-col
                  class="d-flex flex-column justify-content-between"
                >
                  <div>
                    <b-card-title
                      class="text-truncate"
                      style="margin-bottom: 3px"
                    >
                      Participants
                    </b-card-title>
                    <p class="font-small-3">
                      Active
                    </p>
                  </div>
                  <b-card-text class="mb-0 h1 font-weight-bold">
                    {{ activeParticipantsCount }} of {{ participantsCount }}
                  </b-card-text>
                </b-col>
                <b-col
                  class="p-0"
                >
                  <div
                    id="chart"
                  >
                    <apexchart
                      type="radialBar"
                      :options="activeParticipantPercentageChartOption"
                      :series="activeParticipantPercentage"
                    />
                  </div>
                </b-col>
              </b-row>
            </b-card>
          </router-link>
        </b-col>
      </b-row>

      <!-- Unmatched/Matched -->
      <b-row v-if="!isProgramTraining">
        <!-- Mentors -->
        <b-col sm="6">
          <router-link
            :to="{ name: 'champion-participants', params: { filter: {field: 'role', value: userRoles.MENTOR} }}"
            style="color: unset"
          >
            <b-card tag="article">
              <b-col class="p-0">
                <b-card-title class="text-truncate">
                  {{ mentorsCount }} {{ roleAlias(userRoles.MENTOR, program, true) }}
                </b-card-title>
                <div class="d-flex mb-2">
                  <b-col>
                    <b-row align-v="center">
                      <b-avatar
                        square
                        size="40"
                        variant="light-success"
                        class="mr-1"
                      >
                        <feather-icon
                          icon="UsersIcon"
                          size="25"
                        />
                      </b-avatar>
                      <div>
                        <h6 class="font-weight-bolder m-0">
                          Matched
                        </h6>
                        <p class="font-small-3 m-0">
                          {{ matchedMentorsCount }}
                        </p>
                      </div>
                    </b-row>
                  </b-col>
                  <b-col class="d-flex justify-content-end">
                    <b-row align-v="center">
                      <b-avatar
                        square
                        size="40"
                        variant="light-success"
                        class="mr-1"
                      >
                        <feather-icon
                          icon="UserIcon"
                          size="25"
                        />
                      </b-avatar>
                      <div>
                        <h6 class="font-weight-bolder m-0">
                          Unmatched
                        </h6>
                        <p class="font-small-3 m-0">
                          {{ unmatchedMentorsCount }}
                        </p>
                      </div>
                    </b-row>
                  </b-col>
                </div>

                <div class="mb-2">
                  <div class="w-100 d-flex justify-content-between">
                    <p class="font-weight-bold m-0">
                      {{ `${matchedMentorsCount} matched out of ${mentorsCount}` }}
                    </p>
                    <p class="m-0">
                      {{ getPercentage(matchedMentorsCount, mentorsCount) }}%
                    </p>
                  </div>
                  <b-progress
                    :value="getPercentage(matchedMentorsCount, mentorsCount)"
                    class="w-100"
                    :class="
                      getPercentage(matchedMentorsCount, mentorsCount) > 50 ?
                        'default-green-chart-color' :
                        'default-chart-color'
                    "
                  />
                </div>

                <b-row>
                  <b-col
                    cols="6"
                    sm="6"
                  >
                    <p class="font-small-3 font-weight-bolder mb-0">
                      Applications opened
                    </p>
                    <p class="font-small-3">
                      {{ programMentorApplicationsOpenDate }}
                    </p>
                  </b-col>
                  <b-col
                    cols="6"
                    sm="6"
                    class="text-right"
                  >
                    <p class="font-small-3 font-weight-bolder mb-0">
                      Applications closed
                    </p>
                    <p class="font-small-3">
                      {{ programMentorApplicationsCloseDate }}
                    </p>
                  </b-col>
                </b-row>
              </b-col>
            </b-card>
          </router-link>
        </b-col>

        <!-- Mentees -->
        <b-col sm="6">
          <router-link
            :to="{ name: 'champion-participants', params: { filter: {field: 'role', value: userRoles.MENTEE} }}"
            style="color: unset"
          >
            <b-card tag="article">
              <b-col class="p-0">
                <b-card-title class="text-truncate">
                  {{ menteesCount }} {{ roleAlias(userRoles.MENTEE, program, true) }}
                </b-card-title>
                <div class="d-flex mb-2">
                  <b-col>
                    <b-row align-v="center">
                      <b-avatar
                        square
                        size="40"
                        variant="light-success"
                        class="mr-1"
                      >
                        <feather-icon
                          icon="UsersIcon"
                          size="25"
                        />
                      </b-avatar>
                      <div>
                        <h6 class="font-weight-bolder m-0">
                          Matched
                        </h6>
                        <p class="font-small-3 m-0">
                          {{ matchedMenteesCount }}
                        </p>
                      </div>
                    </b-row>
                  </b-col>
                  <b-col class="d-flex justify-content-end">
                    <b-row align-v="center">
                      <b-avatar
                        square
                        size="40"
                        variant="light-success"
                        class="mr-1"
                      >
                        <feather-icon
                          icon="UserIcon"
                          size="25"
                        />
                      </b-avatar>
                      <div>
                        <h6 class="font-weight-bolder m-0">
                          Unmatched
                        </h6>
                        <p class="font-small-3 m-0">
                          {{ unmatchedMenteesCount }}
                        </p>
                      </div>
                    </b-row>
                  </b-col>
                </div>

                <div class="mb-2">
                  <div class="w-100 d-flex justify-content-between">
                    <p class="font-weight-bold m-0">
                      {{ `${matchedMenteesCount} matched out of ${menteesCount}` }}
                    </p>
                    <p class="m-0">
                      {{ getPercentage(matchedMenteesCount, menteesCount) }}%
                    </p>
                  </div>
                  <b-progress
                    :value="getPercentage(matchedMenteesCount, menteesCount)"
                    class="w-100"
                    :class="
                      getPercentage(matchedMenteesCount, menteesCount) > 50 ?
                        'default-green-chart-color' :
                        'default-chart-color'
                    "
                  />
                </div>

                <b-row>
                  <b-col
                    cols="6"
                    sm="6"
                  >
                    <p class="font-small-3 font-weight-bolder mb-0">
                      Applications opened
                    </p>
                    <p class="font-small-3">
                      {{ programMenteeApplicationsOpenDate }}
                    </p>
                  </b-col>
                  <b-col
                    cols="6"
                    sm="6"
                    class="text-right"
                  >
                    <p class="font-small-3 font-weight-bolder mb-0">
                      Applications closed
                    </p>
                    <p class="font-small-3">
                      {{ programMenteeApplicationsCloseDate }}
                    </p>
                  </b-col>
                </b-row>
              </b-col>
            </b-card>
          </router-link>
        </b-col>
      </b-row>

      <b-row class="d-none d-sm-block">
        <b-col>
          <b-card>
            <b-card-title>
              Program champions
            </b-card-title>

            <champions-list />
          </b-card>
        </b-col>
      </b-row>
    </b-col>

    <!-- Timeline for mobile -->
    <b-col
      sm="12"
      class="d-flex d-sm-none"
    >
      <b-card class="w-100">
        <h4 class="mb-2">
          <b-row>
            <b-col
              cols="8"
              class="d-flex align-items-center"
            >
              <feather-icon
                icon="ListIcon"
                size="20"
              />
              <b-card-title class="mb-0 ml-2">
                <router-link
                  :to="{ name: 'champion-program-key-dates-list' }"
                  style="color: unset"
                >
                  Key dates
                </router-link>
              </b-card-title>
            </b-col>
          </b-row>
        </h4>
        <timeline
          :key-dates="keyDates"
          :timezone="programTimezone"
        />
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import { mapGetters } from "vuex";
import {
  BRow,
  BCol,
  BCard,
  BCardTitle,
  BCardSubTitle,
  BCardText,
  BAvatar,
  BProgress
} from 'bootstrap-vue';
import Timeline from "@/views/components/common/KeyDates.vue";
import { AOM_GREEN_COLOR } from "@/libs/utils";
import { userRoles } from '@models/userRoles';
import { matchStatus, programTypes } from '@models';
import { durationUnitsDisplay } from "@/models/enums/durationUnits";
import { localeDateStringFromIsoDateTime, localeDateStringFromIsoDateTimeWithoutTimezone } from "@/libs/utils";
import ChampionsList from '@/views/apps/admin/champion/ChampionsList.vue';
import ProgramAlerts from './components/ProgramAlerts.vue';
import { roleAlias } from "@/@aom-core/utils/utils";

export default {
  name: 'ChampionsDashBoard',
  components: {
    BRow,
    BCol,
    BCard,
    BCardTitle,
    BCardSubTitle,
    BCardText,
    Timeline,
    BAvatar,
    BProgress,
    ChampionsList,
    ProgramAlerts
  },
  data() {
    return {

      matchCapacityChartData: [10],
      participantsChartData: [100],
      defaultChartColor: "#FFA500",
      defaultGreenChartColor: AOM_GREEN_COLOR,
      defaultChartOptions: {
        chart: {
          type: 'radialBar',
          renderTo: 'container',
          spacingTop: 0,
          spacingBottom: 0,
          spacingLeft: 0,
          spacingRight: 0,
          offsetY: 0,
        },
        plotOptions: {
          radialBar: {
            hollow: {
              margin: 0,
              size: '70%',
              image: undefined,
              imageOffsetX: 0,
              imageOffsetY: 0,
              position: 'front',
            },
            track: {
              strokeWidth: '95%',
              margin: 0,
            },
            dataLabels: {
              showOn: 'always',
              name: {
                show: false,
              },
              value: {
                formatter: function(val) {
                  return parseInt(val) + "%";
                },
                color: '#003366',
                fontSize: '20px',
                lineHight: '1',
                show: true,
                offsetY: 6,
                offsetX:0,
              }
            }
          }
        },
        fill: {
          type: 'gradient',
          gradient: {
            shade: 'light',
            type: 'horizontal',
            shadeIntensity: 0.4,
            inverseColors: false,
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 50, 53, 91]
          },
        },
      },
      userRoles,
      matchStatus
    };
  },

  computed: {
    ...mapGetters("programs", ["defaultProgram"]),
    isProgramTraining () {
      return this.defaultProgram?.type_id === programTypes.TRAINING;
    },
    programTimezone() {
      if (this.defaultProgram && this.defaultProgram.time_zone) {
        return this.defaultProgram.time_zone;
      }
      return '';
    },

    keyDates() {
      if (this.defaultProgram && this.defaultProgram.key_dates) {
        const keyDates = [...this.defaultProgram.key_dates];
        return keyDates.sort((a, b) => {
          const dateA = new Date(a.date);
          const dateB = new Date(b.date);

          return dateA.getTime() - dateB.getTime();
        });
      }
      return [];
    },

    program() {
      if (this.defaultProgram) {
        return this.defaultProgram;
      }

      return null;
    },

    activeParticipantsCount() {
      if (!this.program) { return 0; }
      return this.program.active_participants_count || 0;
    },

    participantsCount() {
      if (!this.program) { return 0; }
      return this.program.participants_count || 0;
    },

    activeParticipantPercentage() {
      if (this.participantsCount === 0) { return [0]; }

      return [Math.round(this.activeParticipantsCount * 100 / this.participantsCount)];
    },

    activeParticipantPercentageChartOption() {
      if (this.activeParticipantPercentage[0] > 50) {
        return {...this.defaultChartOptions, colors: [this.defaultGreenChartColor]};
      }

      return {...this.defaultChartOptions, colors: [this.defaultChartColor]};
    },

    menteesCount() {
      if (!this.program) { return 0; }
      return this.program.mentees_count || 0;
    },

    mentorsCount() {
      if (!this.program) { return 0; }
      return this.program.mentors_count || 0;
    },

    unmatchedMenteesCount() {
      if (!this.program) { return 0; }
      return this.program.unmatched_mentees_count || 0;
    },

    matchedMenteesCount() {
      if (!this.program) { return 0; }
      return this.menteesCount - this.program.unmatched_mentees_count || 0;
    },

    unmatchedMenteesPercentage() {
      if (this.menteesCount === 0) { return [0]; }

      return [Math.round(this.unmatchedMenteesCount * 100 / this.menteesCount)];
    },

    unmatchedMenteesPercentageChartOption() {
      if (this.unmatchedMenteesPercentage[0] > 50) {
        return {...this.defaultChartOptions, colors: [this.defaultChartColor]};
      }

      return {...this.defaultChartOptions, colors: [this.defaultGreenChartColor]};
    },

    unmatchedMentorsCount() {
      if (!this.program) { return 0; }
      return this.program.unmatched_mentors_count || 0;
    },

    matchedMentorsCount() {
      if (!this.program) { return 0; }
      return this.mentorsCount - this.program.unmatched_mentors_count || 0;
    },

    unmatchedMentorsPercentage() {
      if (this.mentorsCount === 0) { return [0]; }

      return [Math.round(this.unmatchedMentorsCount * 100 / this.mentorsCount)];
    },

    unmatchedMentorsPercentageChartOption() {
      if (this.unmatchedMentorsPercentage[0] > 50) {
        return {...this.defaultChartOptions, colors: [this.defaultChartColor]};
      }

      return {...this.defaultChartOptions, colors: [this.defaultGreenChartColor]};
    },

    matchesCount() {
      if (!this.program) { return 0; }
      return this.program.matches_count || 0;
    },


    matchLimit() {
      if (!this.program) { return 0; }

      return this.program.match_limit || 0;
    },

    displayCurrentMatchWidget() {
      return this.program.type.id === 1;
    },

    matchUsage() {
      if (this.matchLimit === 0) { return [0]; }

      return [Math.round(this.matchesCount * 100 / this.matchLimit)];
    },

    matchUsageChartOption() {
      if (this.matchUsage[0] > 50) {
        return {...this.defaultChartOptions, colors: [this.defaultGreenChartColor]};
      }

      return {...this.defaultChartOptions, colors: [this.defaultChartColor]};
    },

    activeMatchesCount() {
      if (!this.program) { return 0; }
      return this.program.matches_count || 0;
    },

    currentMatchPercentage() {
      if (this.matchLimit === 0) { return [0]; }

      return [Math.round(this.activeMatchesCount * 100 / this.matchLimit)];
    },

    currentMatchPercentageChartOptions() {
      if (this.currentMatchPercentage[0] > 50) {
        return {...this.defaultChartOptions, colors: [this.defaultGreenChartColor]};
      }

      return {...this.defaultChartOptions, colors: [this.defaultChartColor]};
    },

    programLanguage() {
      return this.program?.languages.map(l => l.name).join(", ");
    },

    programDefaultChampion() {
      return this.program?.default_champion?.full_name || '';
    },

    programMatchDuration() {
      const durationUnitDisplay = durationUnitsDisplay.filter(d => d.id === this.program?.match_duration_unit) ?? null;
      return `${this.program?.match_duration_value} ${durationUnitDisplay ? durationUnitDisplay[0].name : ""}`;
    },

    programLicenceStartDate() {
      return localeDateStringFromIsoDateTime(this.program?.licence_start_date);
    },

    programLicenceEndDate() {
      return localeDateStringFromIsoDateTime(this.program?.licence_end_date);
    },

    mentorApplications () {
      return this.program.application_set && this.program.application_set.applications.filter(app => app.roles.find(r => r.id === userRoles.MENTOR));
    },
    menteeApplications () {
      return this.program.application_set && this.program.application_set.applications.filter(app => app.roles.find(r => r.id === userRoles.MENTEE));
    },

    programMentorApplicationsOpenDate() {
      return this.mentorApplications && localeDateStringFromIsoDateTimeWithoutTimezone(this.mentorApplications[0].scheduled_actions[0].absolute_date);
    },

    programMentorApplicationsCloseDate() {
      return this.mentorApplications && localeDateStringFromIsoDateTimeWithoutTimezone(this.mentorApplications[0].scheduled_actions[1].absolute_date);
    },

    programMenteeApplicationsOpenDate() {
      return this.menteeApplications && localeDateStringFromIsoDateTimeWithoutTimezone(this.menteeApplications[0].scheduled_actions[0].absolute_date);
    },

    programMenteeApplicationsCloseDate() {
      return this.menteeApplications && localeDateStringFromIsoDateTimeWithoutTimezone(this.menteeApplications[0].scheduled_actions[1].absolute_date);
    },
    programMenteeAlias() {
      const alias = this?.defaultProgram?.program_roles.find(r => r.role_id === userRoles.MENTEE)?.alternative_role_name || 'Mentee';
      if(alias && this.menteesCount > 1) {
        return `${alias}s`;
      }
      return alias;
    },
    programMentorAlias() {
      const alias = this?.defaultProgram?.program_roles.find(r => r.role_id === userRoles.MENTOR)?.alternative_role_name || 'Mentor';
      if(alias && this.mentorsCount >  1) {
        return `${alias}s`;
      }
      return alias;
    }
  },

  methods: {
    getPercentage(achieved, total) {
      if (!achieved) {
        return 0;
      }
      return (
        Math.round(
          (achieved * 100) /
            total
        ) || 0
      );
    },
  },
  setup() {
    return {

      roleAlias
    };
  }
};
</script>
<style lang="scss" scoped>
::v-deep .default-chart-color {
  .progress-bar {
    background-color: #FFA500 !important;
  }
}
::v-deep .default-green-chart-color {
  .progress-bar {
    background-color: #9bcc65 !important;
  }
}
</style>